export const DataPricelist = [
  {
    paket: "HEMAT",
    harga: "150K",
    items: [
      "kamu tinggal TERIMA BERES AJA!",
      "Posting SETIAP HARI termasuk hari LIBUR",
      "Ide Konten & Materi Konten dari kami",
      "Copywriting Konten & Caption",
      "Hashtag yang teriset dan relate di setiap konten",
      "1 konten = 1 design + copywriting + caption & hashtag",
      "Total jatah konten 9 Konten Gambar (Jpg)",
      "Setiap hari keluar atau posting 1 konten",
      "Bisa Request konsep / referensi tone warna",
      "Free : Make over bio",
    ],
  },
  {
    paket: "CERMAT",
    harga: "350K",
    items: [
      "kamu tinggal TERIMA BERES AJA!",
      "Posting SETIAP HARI termasuk hari LIBUR",
      "Ide Konten & Materi Konten dari kami",
      "Copywriting Konten & Caption",
      "Hashtag yang teriset dan relate di setiap konten",
      "1 konten = 1 design + copywriting + caption & hashtag",
      "Total jatah konten 15 Konten (8 video & 7 gambar) ",
      "Setiap hari keluar atau posting 1 konten",
      "Bisa Request konsep / referensi tone warna",
      "Free : Make over bio",
    ],
  },
  {
    paket: "REGULER",
    harga: "500K",
    items: [
      "kamu tinggal TERIMA BERES AJA!",
      "Posting SETIAP HARI termasuk hari LIBUR",
      "Ide Konten & Materi Konten dari kami",
      "Copywriting Konten & Caption",
      "Hashtag yang teriset dan relate di setiap konten",
      "1 konten = 1 design + copywriting + caption & hashtag",
      "Total jatah konten 30 Konten terdiri dari full gambar (jpg)",
      "Setiap hari keluar atau posting 1 konten",
      "Bisa Request konsep / referensi tone warna",
      "Free : Make over bio",
      "Free : Multiple Link Bio Iinstagram",
    ],
  },
  {
    paket: "BUSSINES",
    harga: "700K",
    items: [
      "kamu tinggal TERIMA BERES AJA!",
      "Posting SETIAP HARI termasuk hari LIBUR",
      "Ide Konten & Materi Konten dari kami",
      "Copywriting Konten & Caption",
      "Hashtag yang teriset dan relate di setiap konten",
      "1 konten = 1 design + copywriting + caption & hashtag",
      "Total jatah konten 30 Konten terdiri dari 15 konten Gambar (Jpg), 15 konten VIDEO REELS",
      "Setiap hari keluar atau posting 1 konten (jam prime time)",
      "Bisa Request konsep / referensi tone warna",
      " 4 Icon Sorotan untuk highlight story ",
      "Free : Make over bio",
      "Free : Multiple Link Bio Iinstagram",
    ],
  },
  {
    paket: "BUSSINES",
    harga: "900K",
    items: [
      "kamu tinggal TERIMA BERES AJA!",
      "Posting SETIAP HARI termasuk hari LIBUR",
      "Ide Konten & Materi Konten dari kami",
      "Copywriting Konten & Caption",
      "Hashtag yang teriset dan relate di setiap konten",
      "1 konten = 1 design + copywriting + caption & hashtag",
      "Total jatah konten 30 Konten terdiri dari 10 Gambar Jpg & 20 Video",
      "Setiap hari keluar atau posting 1 konten (jam prime time)",
      "Bisa Request konsep / referensi tone warna",
      " 4 Icon Sorotan untuk highlight story ",
      "Free : Make over bio",
      "Free : Multiple Link Bio Instagram",
      "Free : Balas Komentar Postingan",
    ],
  },
  {
    paket: "BUSSINES",
    harga: "1200",
    items: [
      "kamu tinggal TERIMA BERES AJA!",
      "Posting SETIAP HARI termasuk hari LIBUR",
      "Ide Konten & Materi Konten dari kami",
      "Copywriting Konten & Caption",
      "Hashtag yang teriset dan relate di setiap konten",
      "1 konten = 1 design + copywriting + caption & hashtag",
      "Total jatah konten 30 Konten terdiri dari Full VIDEO",
      "Setiap hari keluar atau posting 1 konten (jam prime time)",
      "Bisa Request konsep / referensi tone warna",
      " 4 Icon Sorotan untuk highlight story ",
      "Free : Make over bio",
      "Free : Multiple Link Bio Instagram",
      "Free : Balas Komentar Postingan",
    ],
  },
];

export const portfolio = [
  "esteamewaofficial.png",
  "Gulzar_travel.png",
  "bitmebite (2).png",
  "Yana Taste.png",
  "Kambing Palembang.png",
  "Workshop Advertising.png",
  "Dan.deo (1).png",
  "Tahu Logi.png",
  "Endul By New Hope.png",
  "Dwarna.png",
  "Bank Sampah Bina Mandiri Surabaya.png",
  "Omah Catering.png",
  "cocogeneral.id.png",
  "ptsaka.png",
  "Stikes RS Anwarmedika.png",
  "Shoperior.png",
  "Deli Catering Sby.png",
  "Sub Jersey.png",
  "Syza Simply (1).png",
  "elbarokihealth.png",
  "Hafizh_Aqiqah.png",
  "elbarokijayacatering.png",
  "Umroh Ghifany.png",
  "Anara.png",
  "Sewa BBQ.png",
  "Tahu Walik Bu Jaim.png",
  "Janji Madu.png",
  "Qausa.id.png",
  "MM Workshop.png",
  "Sagoqu.png",
  "Trigger Berkarya.png",
  "Azzam MPASI.png",
  "Omah_resto.png",
  "Sub Academy.png",
  "Sarae.png",
  "Wahana (1).png",
  "lovely.beauty.png",
  "Top Max.png",
  "Trinixsystems.png",
  "Tungku_utinanti.png",
  "Jempoly kriuk.png",
  "Akun Bareng Store.png",
  "Safron.png",
  "Genduts Bomboloni.png",
  "Esj Property.png",
  "Herbalito.png",
  "Bimbel Privat.png",
  "Cucian Coin Laundry.png",
  "Jaket Online.png",
  "Sehati_homecare.png",
  "Aktaco.id.png",
  "Campingin.png",
  "Onic Kitchen.png",
  "gambang.png",
  "Inpack Solution.png",
  "Toko Amalya.png",
  "Jadahtempe.png",
  "Es Teh Prima.png",
];
