import React, { useState } from "react";
import SosmedIconComponents from "../components/SosmedIconComponents";
import BulletCompoent from "../components/BulletCompoent";
import FixBottomMenuComponent from "../components/FixBottomMenuComponent";
import MenuComponent from "../components/MenuComponent";
import ButtonComponent from "../components/ButtonComponent";
import LogoComponent from "../components/LogoComponent";
import Font, { Text } from "react-font";
import { GrLineChart } from "react-icons/gr";
import { BsPencilFill } from "react-icons/bs";
import FooterComponent from "../components/FooterComponent";
import ImageSlideShowComponent from "../components/ImageSlideShowComponent";
import { portfolio } from "./DataSupport";
import CallToActionWA from "../components/CallToAction";
import ImageSlideShowHeaderComponents from "../components/ImageSlideShowHeaderComponents";

export default function Portfolio(props: any) {
  const portfolioheader = require("./dist/img/sampleportfolio.png");
  const portfolioheader1 = require("./dist/img/trinixsystems.png");
  const aktor = require("./dist/img/imageAffiliate/aktor.png");
  const portfolioheader2 = require("./dist/img/imageGaleri/imagesquare.png");

  const [cekOpenImage, setCekOpenImage] = useState(false);
  const [hiddenBottomMenu, setHiddenBottomMenu] = useState("block");
  const [openImage, setOpenImage] = useState("hidden");
  const [idPortfolio, setIdPortFolio] = useState(0);

  const cekOpenImage1 = !cekOpenImage;

  const HandleOpenCloseImage = (e: any) => {
    e.preventDefault();
    // console.log("test key : ", e.target.id);
    if (cekOpenImage1) {
      setOpenImage("flex");
      setHiddenBottomMenu("hidden");
      setCekOpenImage(true);
      setIdPortFolio(e.target.id);
    } else {
      setOpenImage("hidden");
      setHiddenBottomMenu("block");
      setCekOpenImage(false);
      setIdPortFolio(0);
    }
  };

  return (
    <>
      <div
        className={`w-screen h-screen fixed z-[99] flex flex-col items-center ${openImage} fadeIn`}
      >
        <h1
          onClick={HandleOpenCloseImage}
          className="absolute cursor-pointer w-fit text-center text-2xl md:text-5xl z-10 text-white font-bold mt-5 md:mt-0 "
        >
          x
        </h1>
        <ImageSlideShowComponent getValueNumber={idPortfolio} />
      </div>
      {/* Header Design */}
      <header className="relative w-full">
        {/* Navbar Menu */}
        <div className="absolute w-full flex justify-center">
          <div className="flex w-11/12 md:w-10/12 lg:w-9/12 z-10 justify-items-center justify-center  md:justify-between py-0 md:py-2 ">
            <LogoComponent className="h-5 md:h-10 lg:h-15 w-auto" />
            <MenuComponent className="text-white self-center text-base lg:text-xl " />
          </div>
          <div
            className={`fixed inset-x-0 bottom-0  md:hidden ${hiddenBottomMenu} `}
          >
            <FixBottomMenuComponent />
          </div>
        </div>
        {/* Hero Section */}
        <div className=" relative lg:grid grid-cols-2 justify-items-center flex justify-around h-[35vh] md:h-[30vh] lg:h-[100vh]">
          <div className="absolute md:static  z-50 self-center text-white w-10/12 md:w-10/12 lg:w-9/12 ">
            <h1 className="text-lg md:text-2xl lg:text-4xl font-bold w-3/4 md:w-full text-left mt-10 uppercase">
              Portfolio Kami By Teman ScaleUp
            </h1>
            <div>
              <div className="flex my-2 lg:my-5 space-x-2 lg:space-x-10">
                <BulletCompoent />
              </div>
            </div>
            <p className="text-sm md:text-xl lg:text-2xl 2xl:text-4xl block lg:flex items-center">
              @teman.scaleup <span className="mx-5 hidden lg:flex">|</span>
              <span className="flex space-x-5 md:ml-3 ml-0 my-2 md:my-0 text-base md:text-2xl lg:text-2xl 2xl:text-3xl ">
                <SosmedIconComponents />
              </span>
            </p>
          </div>
          <div className="absolute -z-1 bottom-0 md:bottom-16 right-0  md:right-1/4 ">
            {/* <img
              src={portfolioheader}
              alt="portfolio"
              className="block lg:hidden h-28 md:h-[20vh] lg:h-[35vh] w-auto bottom-0"
            /> */}
            <ImageSlideShowHeaderComponents
              className={`block md:hidden h-[30vh] md:h-[40vh] lg:h-[35vh] w-full bottom-0 right-0`}
            />

            {/* <img
              src={`${process.env.PUBLIC_URL}/portfolio/mockup/Berkah Cahaya Tangguh.png`}
              alt="portfolio"
              className="hidden lg:block h-40 md:h-80 lg:h-[80vh] w-auto ml-[50vw] mt-[15vh] "
            /> */}
            <ImageSlideShowHeaderComponents
              className={`hidden md:block h-40 md:h-80 lg:h-[80vh] w-full`}
            />
          </div>
        </div>
      </header>

      {/* mainpage */}
      <main className="fadeIn">
        {/* Opening Porfolio Social Media */}
        <section className="">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-0.5 lg:grid-cols-4 my-0.5">
            <div className="bg-yellow-200 ">
              <img
                src={`${process.env.PUBLIC_URL}/portfolio/mockup/Casade Interior.png`}
                alt="Casade Interior"
              />
            </div>
            <div className="bg-violet-200">
              <img
                src={`${process.env.PUBLIC_URL}/portfolio/mockup/Helwa Beuaty care.png`}
                alt="Helwa Beuaty care"
              />
            </div>
            <div className="bg-green-200">
              <img
                src={`${process.env.PUBLIC_URL}/portfolio/mockup/Peace of Akhwat.png`}
                alt="Peace of Akhwat"
              />
            </div>
            <div className="bg-blue-200 md:hidden lg:block">
              <img
                src={`${process.env.PUBLIC_URL}/portfolio/mockup/Maedahi.png`}
                alt="Maedahi"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="w-full aspect-square mb-0.5">
              <img src={portfolioheader2} alt="" />
            </div>
            <div className="w-full aspect-square mb-0.5">
              <img src={portfolioheader2} alt="" />
            </div>
          </div>
        </section>

        {/* button Hubungi Kami */}
        <CallToActionWA />

        {/* title more portfolio */}
        <section className="py-5">
          <Font family="Radley">
            <div className="">
              <div className="text-center text-xl lg:text-7xl font-bold">
                <h1>More Portfolio</h1>
              </div>
            </div>
          </Font>
        </section>
        {/* Sosial Media Management Portfolio */}
        <section>
          <Font family="Radley">
            <div className="flex gap-2 items-center justify-center">
              <BsPencilFill />
              <h1 className="text-black lg:text-3xl">
                Layanan Sosial Media Manajemen
              </h1>
            </div>
            <div className="flex justify-center">
              <div className="h-0.5 w-1/4 rounded-full bg-black my-2"></div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
              {portfolio.map((p, key) => {
                return (
                  <div
                    className="w-full aspect-square mb-0.5"
                    onClick={HandleOpenCloseImage}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/portfolio/mockup/${p}`}
                      alt={p}
                      id={`${key}`}
                      className=" cursor-pointer"
                    />
                  </div>
                );
              })}
            </div>
          </Font>
        </section>

        {/* button Hubungi Kami */}

        <CallToActionWA />
      </main>
      <FooterComponent />
      <div className="h-16 bg-zinc-400 md:hidden"></div>
    </>
  );
}
