import React from "react";
import { CiFacebook, CiInstagram, CiYoutube } from "react-icons/ci";
import { BsTiktok, BsLinkedin, BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function SosmedIconComponents() {
  return (
    <>
      <Link
        to="https://www.facebook.com/profile.php?id=100094087694313"
        target="_blank"
      >
        <CiFacebook className="text-white" />
      </Link>
      <Link to="https://www.instagram.com/teman.scaleup/" target="_blank">
        <CiInstagram className="text-white" />
      </Link>
      <Link
        to="https://www.tiktok.com/@temanscaleup?lang=id-ID"
        target="_blank"
      >
        <BsTiktok className="text-white" />
      </Link>
      <Link
        to="https://www.youtube.com/channel/UCU5YwDMxQ6lx2cQDWHKouCg"
        target="_blank"
      >
        <CiYoutube className="text-white" />
      </Link>
      <Link
        to="https://www.linkedin.com/company/92608951/admin/feed/posts/"
        target="_blank"
      >
        <BsLinkedin className="text-white" />
      </Link>
      <Link to="https://wa.wizard.id/57411b" target="_blank">
        <BsWhatsapp className="text-white" />
      </Link>
    </>
  );
}
