import React, { useState } from "react";
import LogoComponent from "./LogoComponent";
import ButtonComponent from "./ButtonComponent";
import Font, { Text } from "react-font";

function CallToActionWA() {
  const [nameClient, setNameClient] = useState("");
  const [igAccountClient, setigAccountClient] = useState("");
  const [showForm, setShowForm] = useState("hidden");
  const [hideButton, setHideButton] = useState("block");

  const handleShowform = () => {
    setShowForm("block");
    setHideButton("hidden");
  };

  const handleChangeFormContact = (e: any) => {
    setNameClient(e.target.value);
  };

  const handleChangeFormContactIGAccount = (e: any) => {
    setigAccountClient(e.target.value);
  };

  const HandleClickWhatsappContact = (e: any) => {
    e.preventDefault();
    window.open(
      `https://api.whatsapp.com/send/?phone=%206281215058958&text=Haloo%20kak..%20Saya%20${nameClient}%20Mau%20konsultasi%20seputar%20konten%20instagram%20bisnis%20saya%20${igAccountClient}`,
      "_blank"
    ) ||
      window.location.replace(
        `https://api.whatsapp.com/send/?phone=%206281215058958&text=Haloo%20kak..%20Saya%20${nameClient}%20Mau%20konsultasi%20seputar%20konten%20instagram%20bisnis%20saya%20${igAccountClient}`
      );
    setNameClient("");
    setigAccountClient("");
  };
  return (
    <>
      <section className="">
        <div className=" h-full flex justify-center items-center bg-blue-200 px-2 py-2">
          <div className="bg-white rounded-xl py-5 flex flex-col my-10 px-5 w-full md:w-1/2 lg:w-1/3">
            <div className="flex flex-col items-center justify-center ">
              <h1 className="font-bold text-center text-lg my-2 ">
                <Text family="Radley">Ingin Konsultasi Dengan Team</Text>
              </h1>
              <LogoComponent className="w-fit h-8 mb-3 " />
            </div>
            <Font family="Radley">
              <h1 className="text-center text-sm md:text-lg my-3">
                Silahkan Hubungi Kami
              </h1>
            </Font>
            <ButtonComponent
              text="Klik Disini"
              className={` bg-blue-600 text-white text-sm py-2 px-3 my-3 md:text-lg ${hideButton}`}
              onClick={handleShowform}
            />
            <form
              onSubmit={(e: any) => HandleClickWhatsappContact(e)}
              className={`grid grid-cols-[24%_1%_75%] items-center ${showForm}`}
            >
              <label htmlFor="nameServiceOrder" className="">
                Nama
              </label>{" "}
              :
              <input
                type="text"
                placeholder="Masukkan Nama"
                id="nameServiceOrder"
                name="nameServiceOrder"
                value={nameClient}
                required
                className="mx-3 my-2 border-2"
                onChange={(e: any) => {
                  handleChangeFormContact(e);
                }}
              />
              <label htmlFor="igAccountClient">Akun IG</label> :
              <input
                type="text"
                placeholder="Masukkan @NamaAkunIG"
                value={igAccountClient}
                id="igAccountClient"
                name="igAccountClient"
                className="mx-3 my-2 border-2"
                onChange={(e: any) => {
                  handleChangeFormContactIGAccount(e);
                }}
              />
              <input
                type="submit"
                className="col-span-3 self-items-center w-full rounded-3xl cursor-pointer bg-blue-600 text-white text-sm py-2 px-3 my-3 md:text-lg"
                value={"Konsultasi Via WA"}
              />
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default CallToActionWA;
