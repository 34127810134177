import React from "react";

export default function BulletCompoent() {
  return (
    <>
      <div className="aspect-square h-3 md:h-5 2xl:h-9 rounded-full bg-[#fff714]"></div>
      <div className="aspect-square h-3 md:h-5 2xl:h-9 rounded-full bg-[#fff714]"></div>
      <div className="aspect-square h-3 md:h-5 2xl:h-9 rounded-full bg-[#fff714]"></div>
      <div className="aspect-square h-3 md:h-5 2xl:h-9 rounded-full bg-[#fff714]"></div>
      <div className="aspect-square h-3 md:h-5 2xl:h-9 rounded-full bg-[#fff714]"></div>
    </>
  );
}
